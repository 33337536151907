import React from 'react'
import { Container } from '../../../utils/Components'
import c from '../services/FooterService.module.scss'

const FooterService = () => {
  return (
    <div className={c.serviceInner}>
      <Container>
      </Container>
    </div>
  )
}

export default FooterService